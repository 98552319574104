// jquery
import $ from '../../modules/jquery_loader';

// entrie application
import * as app from './application.js';

$(function(){
  // エンターキーでサブミット
  $('input[type="password"], input[type="email"]').keypress(function (e) {
    if(e.which == 13) {
      document.forms[0].submit();
    }
  });
});