// タブ切り替え
export function tabSwitch ( showId ) {
  // タブ
  const tabs = document.querySelectorAll(".tab-area01 li, .tab-area02 li");
  // コンテンツ
  const contents = document.querySelectorAll(".tab-main .tab-block01, .tab-main .tab-block02");

  // 表示対象タブ
  const showTab = document.getElementById(showId + "-tab");

  if (showTab) {
    // 全非表示
    tabs.forEach( (tab) => {
      tab.classList.remove("select");
    });
    contents.forEach( (content) => {
      content.style.display = "none";
    });

    // 対象表示
    showTab.classList.add("select");
    // 対象タブと同じ順番のコンテンツを表示
    const index = [].indexOf.call(tabs, showTab);
    contents[index].style.display = "block";
  }

};
